import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Scrollspy from 'react-scrollspy'
import { Menu, Dropdown } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { Link } from 'gatsby'

import { DrawerContext } from '../contexts/DrawerContext'
import SubMenu from 'antd/lib/menu/SubMenu'

const antDropDownMenuStyles = {
  zIndex: 9999,
  backgroundColor: '#893E41',
  color: '#ffffff',
  paddingBottom: 0,
  borderRadius: 12,
}

const antDropDownMenuItemStyles = {
  fontSize: '1.1rem',
  backgroundColor: '#893E41',
  color: '#ffffff',
  paddingTop: 25,
  minHeight: 65,
  borderBottom: '0.025rem inset #ffffff',
}

const ScrollSpyMenu = ({ services, className, menuItems, drawerClose, ...props }) => {
  const { dispatch } = useContext(DrawerContext)
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [isDropdownOnHover, setIsDropdownOnHover] = useState(false)

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // empty array for scrollspy items
  const scrollItems = []

  // update menu items passed in to add an id and set the staticLink value if a path is supplied instead of a reference
  menuItems = menuItems.map(item => {
    item.id = `${item.path.slice(1)}_menu_item`
    if (!item?.subItemKey) {
      item.staticLink = true
    }

    return item
  })

  // convert menu path to scrollspy items
  menuItems.forEach(item => {
    scrollItems.push(item.path.slice(1))
  })

  // Add all classs to an array
  const addAllClasses = ['scrollspy__menu']

  // className prop checking
  if (className) {
    addAllClasses.push(className)
  }

  // Close drawer when click on menu item
  const toggleDrawer = e => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    dispatch({
      type: 'TOGGLE',
    })
  }

  const getDropdownOverlay = menuItem => {
    if (!menuItem.subItemKey === 'services') {
      console.warn(`Services are the only navigation item with configured sub items. Unknown subItemKey ${menuItem.subItemKey}`)
      return null
    }

    return (
      <Menu style={antDropDownMenuStyles}>
        {services?.nodes?.map(node => {
          const fields = node.fields
          const frontmatter = node.frontmatter
          return (
            <Menu.Item
              key={`${frontmatter.title}`}
              style={antDropDownMenuItemStyles}
              onMouseEnter={() => setIsDropdownOnHover(true)}
              onMouseLeave={() => setIsDropdownOnHover(false)}
            >
              <Link id={`${frontmatter.title}`} to={`/services${fields.slug}`}>
                {frontmatter.title}
              </Link>
            </Menu.Item>
          )
        })}
      </Menu>
    )
  }

  const getNestedMenu = menuItem => {
    if (!menuItem.subItemKey === 'services') {
      console.warn(`Services are the only navigation item with configured sub items. Unknown subItemKey ${menuItem.subItemKey}`)
      return null
    }

    return (
      <Menu mode='inline' style={{ width: '100%', margin: 0, padding: 0, marginLeft: '-25px', borderRight: 'none' }}>
        <SubMenu key={menuItem.id} title={menuItem.label} style={{ fontSize: 20, marginBottom: 0, paddingBottom: 0 }}>
          <Menu.ItemGroup key='subMenu'>
            {services?.nodes?.map(node => {
              const fields = node.fields
              const frontmatter = node.frontmatter
              return (
                <Menu.Item key={`${frontmatter.title}`} onClick={toggleDrawer} style={{ borderColor: 'white', borderBottom: '5px' }}>
                  <Link id={`${frontmatter.title}`} to={`/services${fields.slug}`}>
                    {frontmatter.title}
                  </Link>
                </Menu.Item>
              )
            })}
          </Menu.ItemGroup>
        </SubMenu>
      </Menu>
    )
  }

  return (
    <Scrollspy items={scrollItems} className={addAllClasses.join(' ')} drawerClose={drawerClose} {...props}>
      {menuItems.map((menu, index) => (
        <li key={`menu-item-${index}`}>
          {menu.staticLink ? (
            <a id={menu.id} href={menu.path}>
              {menu.label}
            </a>
          ) : (
            <>
              {windowSize?.width < 1231 ? (
                <>{getNestedMenu(menu)}</>
              ) : (
                <Dropdown
                  overlay={getDropdownOverlay(menu)}
                  arrow={true}
                  visible={isDropdownVisible || isDropdownOnHover}
                  onVisibleChange={showDropdown => {
                    if (!showDropdown) {
                      setTimeout(() => setIsDropdownVisible(false), 1250)
                    } else {
                      setIsDropdownVisible(true)
                    }
                  }}
                  placement='bottomCenter'
                >
                  <a onClick={e => e.preventDefault()}>
                    {menu.label} <DownOutlined />
                  </a>
                </Dropdown>
              )}
            </>
          )}
        </li>
      ))}
    </Scrollspy>
  )
}

ScrollSpyMenu.propTypes = {
  /** className of the ScrollSpyMenu. */
  className: PropTypes.string,

  /** menuItems is an array of object prop which contain your menu
   * data.
   */
  menuItems: PropTypes.array.isRequired,

  /** Class name that apply to the navigation element paired with the content element in viewport. */
  currentClassName: PropTypes.string,

  /** Class name that apply to the navigation elements that have been scrolled past [optional]. */
  scrolledPastClassName: PropTypes.string,

  /** HTML tag for Scrollspy component if you want to use other than <ul/> [optional]. */
  componentTag: PropTypes.string,

  /** Style attribute to be passed to the generated <ul/> element [optional]. */
  style: PropTypes.object,

  /** Offset value that adjusts to determine the elements are in the viewport [optional]. */
  offset: PropTypes.number,

  /** Name of the element of scrollable container that can be used with querySelector [optional]. */
  rootEl: PropTypes.string,

  /**
   * Function to be executed when the active item has been updated [optional].
   */
  onUpdate: PropTypes.func,
}

ScrollSpyMenu.defaultProps = {
  componentTag: 'ul',
  currentClassName: 'is-current',
}

export default ScrollSpyMenu
