import React from 'react'
import PropTypes from 'prop-types'
import { FiMail, FiPhone } from 'react-icons/fi'
import Box from '../../atoms/Box'
import Text from '../../atoms/Text'
import Heading from '../../atoms/Heading'
import Button from '../../atoms/Button'
import Container from '../../common/UI/Container'
import Input from '../../atoms/Input'

const ContactUsSection = ({ sectionWrapper, row, col, secTitleWrapper, secHeading, secText, title, description, buttonWrapper, button }) => {
  return (
    <Box {...sectionWrapper} id='contactus_section'>
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content='CONTACT US' />
          <Heading
            {...secHeading}
            content='For more information about our services or to ask a question, please use this form. One of us will contact you soon.'
          />
        </Box>
        <Box {...row}>
          <Box {...col}>
            <div style={{ maxWidth: '600px', margin: 'auto' }}>
              <form name='contact' method='POST' data-netlify='true'>
                <input type='hidden' name='hidden-contact' value='contact' />
                <div style={{ marginBottom: '20px' }}>
                  <Input label='Name' isMaterial={false} />
                </div>
                <div style={{ marginBottom: '20px' }}>
                  <Input inputType='email' label='Email' isMaterial={false} icon={<FiMail size={15} />} iconPosition='right' />
                </div>
                <div style={{ marginBottom: '20px' }}>
                  <Input label='Phone' isMaterial={false} icon={<FiPhone size={15} />} iconPosition='right' />
                </div>
                <div style={{ marginBottom: '20px' }}>
                  <Input inputType='textarea' label='Message' placeholder='Enter your message' />
                </div>
                <div style={{ width: '100%' }}>
                  <Button style={{ width: '100%', fontSize: '20px' }} title='Submit' type='submit' />
                </div>
              </form>
            </div>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

ContactUsSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
}

ContactUsSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['60px', '70px', '90px', '90px'],
    pb: ['30px', '80px', '80px', '80px'],
  },
  secTitleWrapper: {
    mb: ['55px', '65px'],
    ml: '75px',
    mr: '75px',
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2aa275',
    mb: '5px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  col: {
    width: ['100%', '100%', '75%', '75%'],
  },
  title: {
    fontSize: ['16px', '19px'],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: 0,
  },
  description: {
    fontSize: '15px',
    color: 'textColor',
    lineHeight: '1.75',
    mb: 0,
  },
  buttonWrapper: {
    mt: '50px',
    flexBox: true,
    justifyContent: 'center',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: ['22px'],
    pr: ['22px'],
    colors: 'secondaryWithBg',
    minWidth: '150px',
  },
}

export default ContactUsSection
