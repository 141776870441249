import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Box from '../../atoms/Box'
import Text from '../../atoms/Text'
import Heading from '../../atoms/Heading'
import Container from '../../common/UI/Container'
import Logo from '../../common/UI/Logo'
import FooterWrapper, { List, ListItem } from './footer.style'

import LogoImage from '../../../images/logo.png'

const Footer = ({ logoStyle, textStyle }) => {
  const Data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          contactEmail
          contactPhoneNumber
        }
      }
      dataJson {
        FOOTER {
          title
          menuItems {
            text
            url
          }
        }
      }
    }
  `)

  return (
    <FooterWrapper>
      <Container className='footer_container'>
        <Box className='row'>
          <Box className='col'>
            <Logo href='#' logoSrc={LogoImage} title='Footer Logo' logoStyle={logoStyle} />
            <Text content={Data.site.siteMetadata.contactEmail} {...textStyle} />
            <Text content={Data.site.siteMetadata.contactPhoneNumber} {...textStyle} />
          </Box>

          {Data.dataJson.FOOTER.map((widget, index) => (
            <Box className='col' key={`footer-widget-${index}`}>
              <Heading className='title' content={widget.title} />
              <List>
                {widget.menuItems.map((item, index) => (
                  <ListItem key={`footer-list-item-${index}`}>
                    <a href={item.url} className='ListItem'>
                      {item.text}
                    </a>
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </Box>
      </Container>
    </FooterWrapper>
  )
}

Footer.propTypes = {
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
}

Footer.defaultProps = {
  logoStyle: {
    width: '130px',
    mb: '15px',
  },
  textStyle: {
    color: '#0f2137',
    fontSize: [2, 5],
    mb: '10px',
  },
}

export default Footer
