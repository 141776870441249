import React, { useContext, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { FiPhoneCall } from 'react-icons/fi'
import NavbarWrapper from '../../atoms/Navbar'
import Drawer from '../../atoms/Drawer'
import Button from '../../atoms/Button'
import Logo from '../../atoms/Logo'
import Box from '../../atoms/Box'
import HamburgMenu from '../../common/HamburgMenu'
import Container from '../../common/UI/Container'
import { DrawerContext } from '../../common/contexts/DrawerContext'
import ScrollSpyMenu from '../../common/ScrollSpyMenu'
import IncidentResponseModal from '../../common/modals/IncidentResponse'

import LogoImage from '../../../images/logo-white.png'
import LogoImageAlt from '../../../images/logo.png'

const Navbar = ({ navbarStyle, logoStyle, button, row, menuWrapper, phoneCtaWrapper, phoneMobileCtaWrapper, phoneIcon }) => {
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        MENU_ITEMS {
          label
          path
          offset
          subItemKey
        }
      }
      services: allMarkdownRemark(sort: { fields: [frontmatter___title], order: ASC }, filter: { fields: { collection: { eq: "services" } } }) {
        nodes {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  `)

  const { state, dispatch } = useContext(DrawerContext)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    })
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <div>
      <NavbarWrapper {...navbarStyle} className='saas_navbar'>
        <Container>
          <IncidentResponseModal isOpen={isModalOpen} onClose={closeModal}></IncidentResponseModal>

          <Box {...row}>
            <Logo href='/' logoSrc={LogoImage} title='RAVENii' logoStyle={logoStyle} className='main-logo' />
            <Logo href='/' logoSrc={LogoImageAlt} title='RAVENii' logoStyle={logoStyle} className='logo-alt' />
            <Box {...menuWrapper}>
              <ScrollSpyMenu menuItems={Data.dataJson.MENU_ITEMS} services={Data.services} className='main_menu' offset={-70} />
              <div style={phoneCtaWrapper} className='navbar_button'>
                <FiPhoneCall style={phoneIcon} size={15} />
                <a href='tel:844-317-0944'>(844)-317-0944</a>
              </div>
              <a href='#' onClick={e => e.preventDefault()} className='navbar_button'>
                <Button
                  {...button}
                  title='INCIDENT RESPONSE'
                  onClick={() => {
                    setIsModalOpen(true)
                  }}
                />
              </a>
              <Drawer width='420px' placement='right' drawerHandler={<HamburgMenu barColor='#fff' />} open={state.isOpen} toggleHandler={toggleHandler}>
                <ScrollSpyMenu menuItems={Data.dataJson.MENU_ITEMS} services={Data.services} className='mobile_menu' drawerClose={true} offset={-100} />
                <div style={phoneMobileCtaWrapper} className='navbar_drawer_button'>
                  <FiPhoneCall style={phoneIcon} size={15} />
                  <a href='tel:844-317-0944'>(844)-317-0944</a>
                </div>
                <a href='#' onClick={e => e.preventDefault()} className='navbar_drawer_button'>
                  <Button
                    {...button}
                    title='INCIDENT RESPONSE'
                    onClick={() => {
                      setIsModalOpen(true)
                    }}
                  />
                </a>
              </Drawer>
            </Box>
          </Box>
        </Container>
      </NavbarWrapper>
    </div>
  )
}

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
}

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
    display: 'block',
  },
  row: {
    flexBox: true,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  logoStyle: {
    maxWidth: ['130px', '120px'],
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '700',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    minHeight: 'auto',
    height: '40px',
    backgroundColor: '#B13A3E',
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center',
  },
  phoneCtaWrapper: {
    marginRight: '1rem',
    paddingLeft: '1.25rem',
    minWidth: '175px',
  },
  phoneMobileCtaWrapper: {
    margin: 'auto',
    paddingBottom: '1.25rem',
    fontSize: 'large',
  },
  phoneIcon: {
    marginRight: '0.5rem',
    marginBottom: -3,
  },
}

export default Navbar
