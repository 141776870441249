import React from 'react'
import { Modal } from 'antd'
import Box from '../../../atoms/Box'

const IncidentResponseModal = ({ isOpen, onClose, modalStyles, modalContentStyles, modalContentPhoneStyles }) => {
  return (
    <Modal visible={isOpen} onCancel={onClose} footer={null} width='50%'>
      <Box>
        <div style={modalStyles}>
          <div style={modalContentStyles}>
            <p>Has your network been compromised?</p>
            <p>Before you do anything else, call us.</p>
            <p style={modalContentPhoneStyles}>
              <a href='tel:844-317-0944'>(844)-317-0944</a>
            </p>
            <p>We’ll have a response team assembled quickly to stop the bleeding!</p>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

IncidentResponseModal.defaultProps = {
  modalStyles: {
    width: '100%',
    textAlign: 'center',
  },
  modalContentStyles: {
    marginTop: '25px',
    padding: '0 10px 0 10px',
    fontSize: '1.1rem',
  },
  modalContentPhoneStyles: {
    fontSize: '1.25rem',
    fontStyle: 'italic',
  },
}

export default IncidentResponseModal
